import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromSeriousSystem from '@serious-system';
import * as fromFeatureFlags from '../../../feature-flags';

@Component({
  selector: 'squadbox-message-quick-actions-smart',
  imports: [
    fromSeriousSystem.IconButtonDirective,
    fromSeriousSystem.UseUIBasicIconDirective,
    fromSeriousSystem.UseAiIconDirective,
  ],
  template: `
    @if (ffQuickActions().copy) {
    <button sdIconButton variant="icon" color="neutral" size="xs">
      <svg sdUseUIBasicIcon="copy"></svg>
    </button>
    } @if (ffQuickActions().thumbsUp) {
    <button sdIconButton variant="icon" color="neutral" size="xs">
      <svg sdUseUIBasicIcon="thumbs-up"></svg>
    </button>
    } @if (ffQuickActions().thumbsDown) {
    <button sdIconButton variant="icon" color="neutral" size="xs">
      <svg sdUseUIBasicIcon="thumbs-down"></svg>
    </button>
    } @if (ffQuickActions().starsAi.hasAnyTrueValue) {
    <button sdIconButton variant="icon" color="primary" size="xs">
      <svg sdUseAiIcon="stars"></svg>
    </button>
    }
  `,
  styles: [
    `
      :host {
        @apply flex gap-1;
      }
    `,
  ],
})
export class MessageQuickActionsSmartComponent {
  private readonly featureFlagsStore =
    inject<Store<fromFeatureFlags.FeatureFlagsState>>(Store);
  public readonly ffQuickActions = this.featureFlagsStore.selectSignal(
    fromFeatureFlags.featureFlagsFeature.selectQuickActions
  );
}
